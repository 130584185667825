import './App.css';
import './Assets/css/default.css';
import HomePage from './components/pages/HomePage';
function App() {
  return ( 
    <div className="App">
      <HomePage/>
    </div>
  );
}
export default App;
